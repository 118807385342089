<!-- 
  MODULE REF:
    FILTER
    RETRIEVE DATA
    EXPORT
-->
<template>
  <div :id="scssId" :class="classContainer">
    <!-- IF NO AVAILABLE DATA -->
    <div
      v-if="!this.dataList"
      class="pa-5 d-flex justify-center flex-wrap align-center"
      style="height: 100%"
    >
      <v-card
        class="mx-auto text-center"
        width="50vh"
        color="transparent"
        outlined
      >
        <slot name="noData">
          <!-- <v-img style="opacity: 0.7" src="@/assets/no-data.svg"></v-img> -->
          <div class="pa-4">
            <h3>Tidak ada data tercatat</h3>
          </div>
        </slot>
      </v-card>
    </div>
    <!--END IF NO AVAILABLE -->
    <!-- IF THERE IS -->
    <div v-else>
      <!-- <h2 v-if="isTitle">{{ textTitle }}</h2> -->
      <!-- <v-row v-if="isTitle">
          <v-col cols="12">
          <div class="d-inline-flex align-center mt-3" v-if="isHeader">
            <h3 class="primary--text" style="">Semua {{ textTitle }}</h3>
          </div>
        </v-col> 
      </v-row> -->
    </div>
    <!-- END IF THERE IS -->

    <v-row class="">
      <v-col :cols="dynamicCols">
        <v-card elevation="0" class="pt-8 rounded-lg">
          <!-- <div style="margin-left: 1.7rem; margin-top: 1rem">
            <h3 class="font-weight-medium" v-if="isTitle">{{ textTitle }}</h3>
          </div> -->

          <slot name="dataTable">
            <!-- data table -->
            <v-data-table
              height="55vh"
              :hide-default-header="mobileCard"
              v-model="model"
              :item-key="itemKey"
              fixed-header
              :single-select="singleSelect"
              :headers="headers"
              :items="dataList"
              :options.sync="options"
              :server-items-length="dataPagination.total_data"
              :page="dataPagination.page"
              :loading="loading"
              loading-text="sedang memuat.."
              :no-data-text="noDataText"
              locale="id"
              :items-per-page="dataPagination.itemsPerPage"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 20],
                itemsPerPageText: 'Baris per halaman',
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                showCurrentPage: true,
              }"
            >
              <!-- :header-props="{ 'sort-icon': 'mdi-sort-descending' }" -->
              <template v-slot:[`top`]>
                <div
                  :class="
                    $vuetify.breakpoint.xs
                      ? 'd-flex justify-center align-center flex-wrap'
                      : 'text-left pa-2 d-flex'
                  "
                >
                  <slot name="buttonTop"> </slot>
                  <v-spacer v-show="!$vuetify.breakpoint.xs"></v-spacer>
                  <!-- EXPORT -->
                  <v-btn
                    :small="$vuetify.breakpoint.xs"
                    @click="exportXls"
                    elevation="0"
                    :loading="loading"
                    v-if="isDefaultExport && !$vuetify.breakpoint.smAndDown"
                    :width="$vuetify.breakpoint.xs ? '100' : '120'"
                    class="
                      rounded-md
                      table__btn
                      text-capitalize
                      mx-2
                      white--text
                    "
                    color="teal darken-2"
                  >
                    <v-icon left> mdi-file-export-outline </v-icon>
                    Ekspor
                  </v-btn>
                  <!-- FILTER -->
                  <template v-if="isFilterOnHeader">
                    <v-btn
                      elevation="0"
                      :small="$vuetify.breakpoint.xs"
                      :width="$vuetify.breakpoint.xs ? '100' : '120'"
                      v-if="
                        !isExternalFilterButton && !$vuetify.breakpoint.smAndDown
                      "
                      @click="isFilterVisible = !isFilterVisible"
                      class="
                        rounded-md
                        table__btn
                        text-capitalize
                        mx-2
                        white--text
                      "
                      color="#90A4AE"
                    >
                      <v-icon left> mdi-filter-variant </v-icon>
                      Filter
                    </v-btn>
                  </template>
                </div>
              </template>
              <!-- FILTER -->
              <template v-if="isFilterOnHeader" v-slot:header="{ props }">
                <v-slide-y-transition style="width: 100%">
                  <thead transition="scale-transition" v-if="isFilterVisible">
                    <div>
                      <v-dialog
                        v-model="isFilterVisible"
                        scrollable
                        :fullscreen="$vuetify.breakpoint.xsOnly"
                        max-width="550"
                      >
                        <v-card>
                          <v-card-title class="px-4 py-2">
                            <h5 style="color: #505050; opacity: 0.9">
                              Filter Berdasarkan
                            </h5>
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              color="red"
                              @click="isFilterVisible = false"
                            >
                              <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text style="height: 100%">
                            <v-row
                              class="mt-4"
                              v-for="(head, idx) in props.headers"
                              :key="idx"
                            >
                              <!-- 
                            {{idx}}
                            {{head}}
                            {{filterHeader[idx]}}
                            -->
                              <v-col
                                class="pa-0 mb-2"
                                v-if="
                                  filterHeader[idx].type != '' &&
                                  filterHeader[idx].type !== 'opsi'
                                "
                              >
                                <v-autocomplete
                                  v-if="
                                    filterHeader[idx].type == 'autocomplete' &&
                                    head.value == filterHeader[idx].column
                                  "
                                  :label="filterHeader[idx].label"
                                  :value="generateFilterValue(idx)"
                                  @change="
                                    (newVal) =>
                                      listenerFilterChange(newVal, idx)
                                  "
                                  :items="filterHeader[idx].items"
                                  :item-text="
                                    filterHeader[idx].itemText
                                      ? filterHeader[idx].itemText
                                      : 'name'
                                  "
                                  :item-value="
                                    filterHeader[idx].itemValue
                                      ? filterHeader[idx].itemValue
                                      : 'id'
                                  "
                                  solo
                                  :prepend-inner-icon="
                                    filterHeader[idx].icon
                                      ? filterHeader[idx].icon
                                      : ''
                                  "
                                  color="#72ce86"
                                ></v-autocomplete>

                                <v-text-field
                                  :prepend-inner-icon="
                                    filterHeader[idx].icon
                                      ? filterHeader[idx].icon
                                      : ''
                                  "
                                  color="#72ce86"
                                  v-if="
                                    filterHeader[idx].type == 'text' &&
                                    head.value == filterHeader[idx].column
                                  "
                                  v-model="
                                    filterModel[filterHeader[idx].column].value
                                  "
                                  :label="filterHeader[idx].label"
                                  solo
                                ></v-text-field>

                                <v-dialog
                                  v-if="
                                    filterHeader[idx].type == 'date_range' &&
                                    head.value == filterHeader[idx].column
                                  "
                                  :ref="`dialog-date-picker-range${idx}`"
                                  v-model="
                                    filterModel[filterHeader[idx].columnFilter]
                                      .dialog
                                  "
                                  :return-value.sync="
                                    filterModel[filterHeader[idx].columnFilter]
                                      .value
                                  "
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      :value="
                                        formatDate(
                                          filterModel[
                                            filterHeader[idx].columnFilter
                                          ].value
                                        )
                                      "
                                      :label="filterHeader[idx].label"
                                      prepend-inner-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      solo
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    range
                                    v-model="
                                      filterModel[
                                        filterHeader[idx].columnFilter
                                      ].value
                                    "
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        filterModel[
                                          filterHeader[idx].columnFilter
                                        ].dialog = false
                                      "
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs[
                                          `dialog-date-picker-range${idx}`
                                        ][0].save(
                                          filterModel[
                                            filterHeader[idx].columnFilter
                                          ].value
                                        )
                                      "
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>

                                <v-select
                                  :prepend-inner-icon="
                                    filterHeader[idx].icon
                                      ? filterHeader[idx].icon
                                      : ''
                                  "
                                  color="#72ce86"
                                  v-if="
                                    filterHeader[idx].type == 'select' &&
                                    head.value == filterHeader[idx].column
                                  "
                                  :items="filterHeader[idx].items"
                                  v-model="
                                    filterModel[filterHeader[idx].column].value
                                  "
                                  :label="filterHeader[idx].label"
                                  solo
                                ></v-select>
                                <div
                                  class="text-center"
                                  v-if="filterHeader[idx].type == 'opsi'"
                                ></div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions class="px-4 py-2">
                            <v-spacer></v-spacer>
                            <v-btn
                              :loading="loading"
                              :disabled="loading"
                              color="#90A4AE"
                              width="120"
                              class="mr-2 white--text"
                              @click="doFilter"
                            >
                              <v-icon left>mdi-filter</v-icon> Filter
                            </v-btn>
                            <v-btn
                              outlined
                              color="red"
                              width="120"
                              :loading="loading"
                              :disabled="loading"
                              @click="resetFilterForm"
                            >
                              Reset
                            </v-btn>
                            <!-- <v-btn text small @click="isFilterVisible = false"
                              >Batal</v-btn
                            > -->
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </thead>
                </v-slide-y-transition>
                <!-- </v-scale-transition> -->
              </template>
              <!-- END FILTER -->

              <template
                v-for="(slot, name) in $scopedSlots"
                v-slot:[name]="item"
              >
                <slot :name="name" v-bind="item"></slot>
              </template>

              <template v-slot:[`footer.page-text`]>
                {{ options.page }} - {{ options.itemsPerPage }} dari
                {{ dataPagination.total_data }} total data
                <v-btn @click="retrieveData()" icon class="ml-5">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <slot name="containerRight"> </slot>
            <!-- end data table -->
          </slot>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    isHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * EXPORT
     */
    isDefaultExport: {
      type: Boolean,
      required: false,
      default: true,
    },
    exportConfig: {
      type: Object,
      required: false,
      default: function () {
        return {
          selectItemKey: "",
          url: "",
        };
      },
    },
    /**
     * EXPORT END
     */

    scssId: {
      type: String,
      required: false,
      default: "",
    },
    classContainer: {
      type: String,
      required: false,
      default: "",
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    textTitle: {
      type: String,
      required: false,
      default: "",
    },
    noDataText: {
      type: String,
      required: false,
      default: "data tidak ditemukan.",
    },
    singleSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemKey: {
      type: String,
      required: false,
      default: "",
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * FILTER
     */
    isExternalFilterButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFilterOnHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * supported type: empty (""), text, autocomplete, date_range, opsi, select, slot
     */
    filterHeader: {
      type: Array,
      required: false,
      default: () => [
        {
          type: "",
          column: "", //column header
          columnFilter: "", //column name as docs, required date_range
          valueDefault: "", // date_range []
          label: "",
          items: [], //select [], autocomplete[{}]
          dataType: "", // overwrite check datatype |text, number
        },
      ],
    },
    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    retrieveDataURL: {
      type: String,
      required: true,
      default: "",
    },
    defaultSort: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * RETRIEVE DATA END
     */
  },
  emits: [
    "onMounted",
    "onRetrieveDataStart",
    "onRetrieveDataEnd",
    "onLoadSuccess",
  ],
  data: () => ({
    model: [],
    dynamicCols: 12,
    options: {},
    /**
     * FILTER
     */
    filterModel: {},
    isFilterVisible: false,
    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    disableRetrieveData: false,
    loading: false,
    params: {},
    dataList: [],
    dataPagination: {
      current: 0,
      limit: 0,
      total_data: 0,
      page: 1,
      itemsPerPage: 10,
    },
    /**
     * RETRIEVE DATA END
     */
  }),
  computed: {
    mobileCard() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    /**
     * NOTICE DELETE SOON
     */
    alert() {
      alert("Export sedang dalam proses pengerjaan.");
    },

    /**
     * EXPORT
     */
    async exportXls() {
      this.loading = true;
      try {
        let selectedItem = [];
        if (this.model.length && this.exportConfig.selectItemKey != "") {
          this.model.forEach((element) => {
            selectedItem.push(element[this.exportConfig.selectItemKey]);
          });
        }

        let params = this.params;
        if (selectedItem.length > 0) {
          params.id_jsonarray = JSON.stringify(selectedItem);
        }
        params.page && delete params["page"];
        params.limit && delete params["limit"];
        params.sort && delete params["sort"];

          // filter
      if (this.filterModel) {
        let count = 0;
        for (const key in this.filterModel) {
          if (Object.hasOwnProperty.call(this.filterModel, key)) {
            const element = this.filterModel[key];
            //jika element object tidak kosong maka masukkan params
            if (element.value != "" && element.value != null) {
              if (element.type == "date_range") {
                let comparison = "=";
                let value = "";
                if (element.value.length == 1) {
                  value = element.value[0];
                } else if (element.value.length == 2) {
                  value = `${element.value[0]}::${element.value[1]}`;
                  comparison = "bet";
                }
                params[`filter[${count}][type]`] = "date";
                params[`filter[${count}][field]`] = key;
                params[`filter[${count}][value]`] = value;
                params[`filter[${count}][comparison]`] = comparison;
              } else {
                let isNotNumber = !!isNaN(element.value);
                //overwirte data type check
                if (element.dataType) {
                  if (element.dataType == "number") {
                    isNotNumber = false;
                  } else if (element.dataType == "text") {
                    isNotNumber = true;
                  }
                }
                if (isNotNumber) {
                  params[`filter[${count}][type]`] = "string";
                  params[`filter[${count}][field]`] = key;
                  params[`filter[${count}][value]`] = element.value;
                  params[`filter[${count}][comparison]`] = "<>";
                } else {
                  params[`filter[${count}][type]`] = "numeric";
                  params[`filter[${count}][field]`] = key;
                  params[`filter[${count}][value]`] = element.value;
                  params[`filter[${count}][comparison]`] = "=";
                }
              }
              count++;
            }
          }
        }
      }

        let res = await this.$get(this.exportConfig.url, params);
        console.log(res);
        if (res.status === 200) {
          window.open(res.results.data.export_url, "_blank");
        } else if (res.status === 412) {
          console.log(res);
        } else if (res.status === 400) {
          console.log(res);
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    /**
     * EXPORT END
     */

    /**
     * FILTER
     */
    formatDate(date) {
      if (!date || date.length <= 0) return null;
      let str = "";
      date.forEach((element, index) => {
        const [year, month, day] = element.split("-");
        str += `${month}/${day}/${year}`;
        if (date.length == 2 && index == 0) {
          str += " ~ ";
        }
      });
      return str;
    },
    generateFilterValue(idx) {
      return this.filterHeader[idx].columnFilter
        ? this.filterModel[this.filterHeader[idx].columnFilter].value
        : this.filterModel[this.filterHeader[idx].column].value;
    },
    listenerFilterChange(newVal, idx) {
      this.filterHeader[idx].columnFilter
        ? (this.filterModel[this.filterHeader[idx].columnFilter].value = newVal)
        : (this.filterModel[this.filterHeader[idx].column].value = newVal);
    },
    generateFilterModel() {
      let filterModel = {};
      this.filterHeader.forEach((element) => {
        if (element.columnFilter) {
          filterModel[element.columnFilter] = {
            value: element.valueDefault,
            valueDefault: element.valueDefault,
            type: element.type,
            dialog: false,
            dataType: element.dataType ? element.dataType : "",
            icon: "",
          };
        } else {
          filterModel[element.column] = {
            value: element.valueDefault,
            valueDefault: element.valueDefault,
            type: element.type,
            dialog: false,
            dataType: element.dataType ? element.dataType : "",
            icon: "",
          };
        }
      });
      this.filterModel = filterModel;
    },
    doFilter() {
      this.retrieveData();
      this.isFilterVisible = false;
    },
    resetFilterForm() {
      for (const key in this.filterModel) {
        if (Object.hasOwnProperty.call(this.filterModel, key)) {
          const element = this.filterModel[key];

          // this.filterModel[key].type = "";
          this.filterModel[key].value = element.valueDefault;
        }
      }
      for (const key in this.params) {
        if (Object.hasOwnProperty.call(this.params, key)) {
          if (key.includes("filter[")) {
            delete this.params[key];
          }
        }
      }
      this.retrieveData();
      this.isFilterVisible = false;
    },
    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    async retrieveData() {
      this.$emit("onRetrieveDataStart");
      this.loading = true;
      let sign = this.options.sortDesc && this.options.sortDesc[0] ? "-" : "";

      let params = this.params;
      params.page = this.options.page;
      params.limit = this.options.itemsPerPage;

      if (this.options.sortBy.length > 0 && this.options.sortDesc.length > 0) {
        params.sort = sign + (this.options.sortBy && this.options.sortBy[0]);
      } else {
        params.sort = this.defaultSort;
      }

      // filter
      if (this.filterModel) {
        let count = 0;
        for (const key in this.filterModel) {
          if (Object.hasOwnProperty.call(this.filterModel, key)) {
            const element = this.filterModel[key];
            //jika element object tidak kosong maka masukkan params
            if (element.value != "" && element.value != null) {
              if (element.type == "date_range") {
                let comparison = "=";
                let value = "";
                if (element.value.length == 1) {
                  value = element.value[0];
                } else if (element.value.length == 2) {
                  value = `${element.value[0]}::${element.value[1]}`;
                  comparison = "bet";
                }
                params[`filter[${count}][type]`] = "date";
                params[`filter[${count}][field]`] = key;
                params[`filter[${count}][value]`] = value;
                params[`filter[${count}][comparison]`] = comparison;
              } else {
                let isNotNumber = !!isNaN(element.value);
                //overwirte data type check
                if (element.dataType) {
                  if (element.dataType == "number") {
                    isNotNumber = false;
                  } else if (element.dataType == "text") {
                    isNotNumber = true;
                  }
                }
                if (isNotNumber) {
                  params[`filter[${count}][type]`] = "string";
                  params[`filter[${count}][field]`] = key;
                  params[`filter[${count}][value]`] = element.value;
                  params[`filter[${count}][comparison]`] = "<>";
                } else {
                  params[`filter[${count}][type]`] = "numeric";
                  params[`filter[${count}][field]`] = key;
                  params[`filter[${count}][value]`] = element.value;
                  params[`filter[${count}][comparison]`] = "=";
                }
              }
              count++;
            }
          }
        }
      }

      try {
        const res = await this.$get(this.retrieveDataURL, params);
        if (res.status === 200) {
          if (
            res.results.pagination.current >
              res.results.pagination.total_page &&
            res.results.pagination.total_data > 0
          ) {
            this.options.page = this.options.page - 1;
            this.retrieveData();
          }
          this.dataPagination.total_data = res.results.pagination.total_data;
          this.dataList = res.results.data;
        } else if (res.status === 400) {
          console.log(res);
        }
        this.$emit("onRetrieveDataEnd", res.data);
      } catch (error) {
        this.$emit("onRetrieveDataEnd", error);
        console.log(error);
      }
      this.loading = false;
    },
    resetList() {
      this.dataList = [];
      this.dataPagination = {
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      };
    },
    /**
     * RETRIEVE DATA END
     */
    getDataModel() {
      return this.model;
    },
  },
  mounted() {
    this.$emit("onMounted");
    this.generateFilterModel();
  },
  watch: {
    options: {
      handler() {
        if (this.retrieveData !== null && !this.disableRetrieveData) {
          this.retrieveData();
        }
      },
      deep: true,
    },
    dataList: {
      immediate: true,
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.$emit("onLoadSuccess");
        }
      },
    },
  },
};
</script>

<style lang="scss" >
.v-text-field__details {
  display: none;
}
</style>
