var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classContainer,attrs:{"id":_vm.scssId}},[(!this.dataList)?_c('div',{staticClass:"pa-5 d-flex justify-center flex-wrap align-center",staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"mx-auto text-center",attrs:{"width":"50vh","color":"transparent","outlined":""}},[_vm._t("noData",function(){return [_c('div',{staticClass:"pa-4"},[_c('h3',[_vm._v("Tidak ada data tercatat")])])]})],2)],1):_c('div'),_c('v-row',{},[_c('v-col',{attrs:{"cols":_vm.dynamicCols}},[_c('v-card',{staticClass:"pt-8 rounded-lg",attrs:{"elevation":"0"}},[_vm._t("dataTable",function(){return [_c('v-data-table',{attrs:{"height":"55vh","hide-default-header":_vm.mobileCard,"item-key":_vm.itemKey,"fixed-header":"","single-select":_vm.singleSelect,"headers":_vm.headers,"items":_vm.dataList,"options":_vm.options,"server-items-length":_vm.dataPagination.total_data,"page":_vm.dataPagination.page,"loading":_vm.loading,"loading-text":"sedang memuat..","no-data-text":_vm.noDataText,"locale":"id","items-per-page":_vm.dataPagination.itemsPerPage,"footer-props":{
              itemsPerPageOptions: [5, 10, 15, 20],
              itemsPerPageText: 'Baris per halaman',
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              showCurrentPage: true,
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{class:_vm.$vuetify.breakpoint.xs
                    ? 'd-flex justify-center align-center flex-wrap'
                    : 'text-left pa-2 d-flex'},[_vm._t("buttonTop"),_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xs),expression:"!$vuetify.breakpoint.xs"}]}),(_vm.isDefaultExport && !_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"\n                    rounded-md\n                    table__btn\n                    text-capitalize\n                    mx-2\n                    white--text\n                  ",attrs:{"small":_vm.$vuetify.breakpoint.xs,"elevation":"0","loading":_vm.loading,"width":_vm.$vuetify.breakpoint.xs ? '100' : '120',"color":"teal darken-2"},on:{"click":_vm.exportXls}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-export-outline ")]),_vm._v(" Ekspor ")],1):_vm._e(),(_vm.isFilterOnHeader)?[(
                      !_vm.isExternalFilterButton && !_vm.$vuetify.breakpoint.smAndDown
                    )?_c('v-btn',{staticClass:"\n                      rounded-md\n                      table__btn\n                      text-capitalize\n                      mx-2\n                      white--text\n                    ",attrs:{"elevation":"0","small":_vm.$vuetify.breakpoint.xs,"width":_vm.$vuetify.breakpoint.xs ? '100' : '120',"color":"#90A4AE"},on:{"click":function($event){_vm.isFilterVisible = !_vm.isFilterVisible}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-filter-variant ")]),_vm._v(" Filter ")],1):_vm._e()]:_vm._e()],2)]},proxy:true},(_vm.isFilterOnHeader)?{key:"header",fn:function(ref){
                    var props = ref.props;
return [_c('v-slide-y-transition',{staticStyle:{"width":"100%"}},[(_vm.isFilterVisible)?_c('thead',{attrs:{"transition":"scale-transition"}},[_c('div',[_c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"550"},model:{value:(_vm.isFilterVisible),callback:function ($$v) {_vm.isFilterVisible=$$v},expression:"isFilterVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-4 py-2"},[_c('h5',{staticStyle:{"color":"#505050","opacity":"0.9"}},[_vm._v(" Filter Berdasarkan ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.isFilterVisible = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"100%"}},_vm._l((props.headers),function(head,idx){return _c('v-row',{key:idx,staticClass:"mt-4"},[(
                                _vm.filterHeader[idx].type != '' &&
                                _vm.filterHeader[idx].type !== 'opsi'
                              )?_c('v-col',{staticClass:"pa-0 mb-2"},[(
                                  _vm.filterHeader[idx].type == 'autocomplete' &&
                                  head.value == _vm.filterHeader[idx].column
                                )?_c('v-autocomplete',{attrs:{"label":_vm.filterHeader[idx].label,"value":_vm.generateFilterValue(idx),"items":_vm.filterHeader[idx].items,"item-text":_vm.filterHeader[idx].itemText
                                    ? _vm.filterHeader[idx].itemText
                                    : 'name',"item-value":_vm.filterHeader[idx].itemValue
                                    ? _vm.filterHeader[idx].itemValue
                                    : 'id',"solo":"","prepend-inner-icon":_vm.filterHeader[idx].icon
                                    ? _vm.filterHeader[idx].icon
                                    : '',"color":"#72ce86"},on:{"change":function (newVal) { return _vm.listenerFilterChange(newVal, idx); }}}):_vm._e(),(
                                  _vm.filterHeader[idx].type == 'text' &&
                                  head.value == _vm.filterHeader[idx].column
                                )?_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.filterHeader[idx].icon
                                    ? _vm.filterHeader[idx].icon
                                    : '',"color":"#72ce86","label":_vm.filterHeader[idx].label,"solo":""},model:{value:(
                                  _vm.filterModel[_vm.filterHeader[idx].column].value
                                ),callback:function ($$v) {_vm.$set(_vm.filterModel[_vm.filterHeader[idx].column], "value", $$v)},expression:"\n                                  filterModel[filterHeader[idx].column].value\n                                "}}):_vm._e(),(
                                  _vm.filterHeader[idx].type == 'date_range' &&
                                  head.value == _vm.filterHeader[idx].column
                                )?_c('v-dialog',{ref:("dialog-date-picker-range" + idx),refInFor:true,attrs:{"return-value":_vm.filterModel[_vm.filterHeader[idx].columnFilter]
                                    .value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filterModel[_vm.filterHeader[idx].columnFilter]
                                    , "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.filterModel[_vm.filterHeader[idx].columnFilter]
                                    , "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(
                                        _vm.filterModel[
                                          _vm.filterHeader[idx].columnFilter
                                        ].value
                                      ),"label":_vm.filterHeader[idx].label,"prepend-inner-icon":"mdi-calendar","readonly":"","solo":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(
                                  _vm.filterModel[_vm.filterHeader[idx].columnFilter]
                                    .dialog
                                ),callback:function ($$v) {_vm.$set(_vm.filterModel[_vm.filterHeader[idx].columnFilter]
                                    , "dialog", $$v)},expression:"\n                                  filterModel[filterHeader[idx].columnFilter]\n                                    .dialog\n                                "}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(
                                    _vm.filterModel[
                                      _vm.filterHeader[idx].columnFilter
                                    ].value
                                  ),callback:function ($$v) {_vm.$set(_vm.filterModel[
                                      _vm.filterHeader[idx].columnFilter
                                    ], "value", $$v)},expression:"\n                                    filterModel[\n                                      filterHeader[idx].columnFilter\n                                    ].value\n                                  "}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.filterModel[
                                        _vm.filterHeader[idx].columnFilter
                                      ].dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs[
                                        ("dialog-date-picker-range" + idx)
                                      ][0].save(
                                        _vm.filterModel[
                                          _vm.filterHeader[idx].columnFilter
                                        ].value
                                      )}}},[_vm._v(" OK ")])],1)],1):_vm._e(),(
                                  _vm.filterHeader[idx].type == 'select' &&
                                  head.value == _vm.filterHeader[idx].column
                                )?_c('v-select',{attrs:{"prepend-inner-icon":_vm.filterHeader[idx].icon
                                    ? _vm.filterHeader[idx].icon
                                    : '',"color":"#72ce86","items":_vm.filterHeader[idx].items,"label":_vm.filterHeader[idx].label,"solo":""},model:{value:(
                                  _vm.filterModel[_vm.filterHeader[idx].column].value
                                ),callback:function ($$v) {_vm.$set(_vm.filterModel[_vm.filterHeader[idx].column], "value", $$v)},expression:"\n                                  filterModel[filterHeader[idx].column].value\n                                "}}):_vm._e(),(_vm.filterHeader[idx].type == 'opsi')?_c('div',{staticClass:"text-center"}):_vm._e()],1):_vm._e()],1)}),1),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4 py-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 white--text",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"#90A4AE","width":"120"},on:{"click":_vm.doFilter}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filter ")],1),_c('v-btn',{attrs:{"outlined":"","color":"red","width":"120","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.resetFilterForm}},[_vm._v(" Reset ")])],1)],1)],1)],1)]):_vm._e()])]}}:null,_vm._l((_vm.$scopedSlots),function(slot,name){return {key:name,fn:function(item){return [_vm._t(name,null,null,item)]}}}),{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.options.page)+" - "+_vm._s(_vm.options.itemsPerPage)+" dari "+_vm._s(_vm.dataPagination.total_data)+" total data "),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.retrieveData()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_vm._t("containerRight")]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }